import React from 'react'
import Layout from "../components/layout"
import { Link } from 'gatsby';
import SEO from "../components/seo"
import styled from "styled-components"

const H1 = styled.h1`
  color: goldenrod;
  a {
    text-decoration: none;
    color: coral;
  }
`
const About = () => (
<Layout>
    <SEO title="About" />
    <H1>About Sluush.</H1>
    <Link to="/">&larr; back to home</Link>
</Layout>
)
  
export default About
